<template>
  <div>
    <!-- <vue-gauge :refid="'_' + value" :options="gaugeOptions" /> -->
    <h1>
      Temperature: {{ tempCurrent }}°C Humidity: {{ humCurrent }}% Pressure:
      {{ presCurrent }}Pa
    </h1>

    <canvas id="weather-chart"></canvas>
    <!-- <canvas id="weather-humidity"></canvas> -->
  </div>
</template>

<script>
import Chart from "chart.js";
// import mockData from "../temp-data.json";
// import VueGauge from "vue-gauge";

export default {
  name: "WeatherChart",
  // components: { VueGauge },
  data() {
    return {
      gaugeOptions: {
        chartWidth: window.innerWidth / 2,
        needleValue: this.value / 0.6,
        needleColor: "black",
        arcDelimiters: [15 / 0.6, 40 / 0.6],
        arcColors: ["rgb(61,204,91)", "rgb(239,214,19)", "rgb(255,84,84)"],
        arcLabels: ["15", "40"],
        rangeLabel: ["0", "60"],
        centralLabel: this.value,

        rangeLabelFontSize: 14,
      },
      tempCurrent: 0,
      humCurrent: 0,
      presCurrent: 0,
      value: 0,
      title: "My title",
      weatherData: {
        type: "line",
        data: {
          labels: [],
          datasets: [
            {
              label: "Temperature °C",
              backgroundColor: "rgba(224,89,79,.25)",
              borderColor: "#db382c",
              borderWidth: 4,
              radius: 2,
            },
            {
              label: "Relative Humidity %",
              backgroundColor: "rgba(68,89,171,.25)",
              borderColor: "#2b45ad",
              borderWidth: 4,
              radius: 2,
              yAxisID: "humidity",
            },
            {
              label: "Air Pressure Pa",
              backgroundColor: "rgba(32,133,27,.25)",
              borderColor: "#20851b",
              borderWidth: 4,
              radius: 2,
              yAxisID: "pressure",
            },
            // {
            //   label: "Voltage",
            //   backgroundColor: "rgba(245,245,100,.25)",
            //   borderColor: "#f7f72d",
            //   borderWidth: 4,
            //   radius: 2,
            //   yAxisID: "voltage",
            // },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          lineTension: 0.8,
          scales: {
            y: {
              seriesName: "Temperature",
            },
            yAxes: [
              {
                seriesName: "Temperature",
                title: {
                  text: "Temperature °C",
                  style: {
                    color: "#db382c",
                  },
                },
              },
              {
                seriesName: "Humidity",
                id: "humidity",
                title: {
                  text: "Humidity °C",
                  style: {
                    color: "#2b45ad",
                  },
                },
              },
              {
                seriesName: "Pressure",
                id: "pressure",
                position: "right",
                title: {
                  text: "Pressure",
                  style: {
                    color: "#2b45ad",
                  },
                },
              },
              // {
              //   seriesName: "Voltage",
              //   id: "voltage",
              //   position: "right",
              //   title: {
              //     text: "Voltage",
              //     style: {
              //       color: "#f7f72d",
              //     },
              //   },
              // },
            ],
            xAxes: [
              {
                type: "time",
                time: {
                  displayFormats: {
                    minutes: "HH:mm:ss",
                  },
                },
              },
            ],
          },
        },
      },
    };
  },
  async mounted() {
    this.loaded = false;
    try {
      const ctx = document.getElementById("weather-chart");

      const resp = await fetch(
        "https://us-central1-bietsbot.cloudfunctions.net/httpServer/day-avg"
      );
      const dayData = await resp.json();
      // const dayData = mockData;
      const timeSeries = dayData.map((a) => a.time);
      const temperatureSeries = dayData.map((a) => a.temperature);
      const humiditySeries = dayData.map((a) => a.humidity);
      const pressureSeries = dayData.map((a) => a.pressure);
      // const batterySeries = dayData.map((a) => a.battery);

      this.weatherData.data.labels = timeSeries;
      this.weatherData.data.datasets[0].data = temperatureSeries;
      this.weatherData.data.datasets[1].data = humiditySeries;
      this.weatherData.data.datasets[2].data = pressureSeries;
      // this.weatherData.data.datasets[3].data = batterySeries;

      this.tempCurrent = temperatureSeries[temperatureSeries.length - 1];
      this.humCurrent = humiditySeries[humiditySeries.length - 1];
      this.presCurrent = pressureSeries[pressureSeries.length - 1];

      new Chart(ctx, this.weatherData);
      this.loaded = true;
    } catch (e) {
      console.error(e);
    }
  },
};
</script>
